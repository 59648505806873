<script>
import { Doughnut } from "vue-chartjs";
import ChartDataLabels from "chartjs-plugin-datalabels";

export default {
  extends: Doughnut,
  props: {
    data: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false,
          labels: {
            fontSize: 12,
            fontFamily: "Poppins",
            fontColor: "#424c70",
            boxWidth: 14
          }
        },
        tooltips: {
          backgroundColor: "#424c70",
          titleFontFamily: "Poppins",
          bodyFontFamily: "Poppins",
          displayColors: false
        },
        plugins: {
          datalabels: {
            color: "#424c70",
            font: {
              weight: "bold",
              size: 14,
              family: "Poppins"
            },
            display: function(context) {
              const value = context.dataset.data[context.dataIndex];
              return value > 0;
            },
            formatter: function(value, context) {
              let sum = 0;
              let dataArr = context.dataset.data;
              dataArr.map(data => {
                sum += data;
              });
              let percentage = Math.round((value * 100) / sum) + "%";

              return `${percentage}`;
            }
          }
        }
      }
    };
  },
  methods: {
    render() {
      if (!this.data.labels || this.data.labels.length <= 0) {
        const notfoundData = {
          datasets: [
            {
              backgroundColor: "#ecedf4",
              data: [1]
            }
          ],
          labels: ["Nenhum dado encontrado"]
        };

        this.renderChart(notfoundData, this.options);

        return;
      }

      this.renderChart(this.data, this.options);
    }
  },
  mounted() {
    this.addPlugin([ChartDataLabels]);
  }
};
</script>

<style lang="scss">
#doughnut-chart {
  width: 100% !important;
  max-height: 100% !important;
}
</style>
