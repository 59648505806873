<i18n>
{
  "en": {
    "title": "Dashboards",
    "filter": "Filter",
    "tab-title-1": "Deals",
    "tab-title-2": "Sales",
    "tab-title-3": "Tasks",
    "tab-title-4": "Financial"
  },
  "pt-BR": {
    "title": "Dashboards",
    "filter": "Filtrar",
    "tab-title-1": "Cards",
    "tab-title-2": "Vendas",
    "tab-title-3": "Tarefas",
    "tab-title-4": "Financeiro"
  }
}
</i18n>

<template>
  <div id="dashboard" ref="dashboard">
    <nav-top :title="$t('title')">
      <template #top-content>
        <we-button
          :disabled="!pipelines || pipelines.length <= 0"
          class="primary"
          :text="$t('filter')"
          icon="sliders-h"
          @click="openedFilter = true"
        />
      </template>
      <template #menu>
        <we-nav-x
          v-if="navigationItems && navigationItems.length > 0"
          :items="navigationItems"
          :activeIndex="currentDashboardIndex"
          @select="selectDashboard"
        />
      </template>
    </nav-top>
    <div class="board" ref="board">
      <dashboard-filter v-if="openedFilter" @doFilter="closeFilter" />
      <router-view ref="deals" />
    </div>
  </div>
</template>

<script>
import DashboardFilter from "@/views/Admin/Dashboards/Filter";
export default {
  components: {
    DashboardFilter
  },
  data() {
    return {
      openedFilter: false,
      currentDashboardIndex: 0,
      navigationItems: [
        {
          id: 0,
          name: this.$t("tab-title-1")
        },
        {
          id: 1,
          name: this.$t("tab-title-2")
        },
        {
          id: 2,
          name: this.$t("tab-title-3")
        },
        {
          id: 3,
          name: this.$t("tab-title-4")
        }
      ]
    };
  },
  computed: {
    customer() {
      return this.$store.getters.getCustomer;
    },
    pipelines() {
      return this.$store.getters.getPipelines;
    }
  },
  methods: {
    selectDashboard(event) {
      if (this.currentDashboardIndex === event) return;

      this.$refs.board.scrollTo(0, 0);

      this.currentDashboardIndex = event;

      if (this.navigationItems[event].name === this.$t("tab-title-2")) {
        this.$router.push({ name: "dashboardSales" });
      } else if (this.navigationItems[event].name === this.$t("tab-title-3")) {
        this.$router.push({ name: "dashboardTasks" });
      } else if (this.navigationItems[event].name === this.$t("tab-title-4")) {
        this.$router.push({ name: "dashboardFinancial" });
      } else {
        this.$router.push({ name: "dashboardDeals" });
      }
    },
    closeFilter(doFilter) {
      this.openedFilter = false;
      if (doFilter) {
        this.$refs.deals.doRequests();
      }
    }
  },
  beforeRouteUpdate(to, from, next) {
    if (to.name != "dashboard" && this.customer.subscription_plan === "start") {
      this.$store.commit("openUpgradePlan");
    } else {
      next();
    }
  },
  mounted() {
    if (this.$route.name === "dashboardSales") {
      this.currentDashboardIndex = 1;
    } else if (this.$route.name === "dashboardTasks") {
      this.currentDashboardIndex = 2;
    } else if (this.$route.name === "dashboardFinancial") {
      this.currentDashboardIndex = 3;
    } else {
      this.currentDashboardIndex = 0;
    }
  }
};
</script>

<style lang="scss">
#dashboard {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-flow: column;
  position: relative;
  overflow: hidden;

  #nav-top {
    .top .content {
      .we-icon-tooltip {
        margin-left: 16px;

        svg {
          height: 20px;
          width: auto;
        }
      }
    }
  }

  .board {
    flex: 1;
    background: var(--background-1);
    width: 100%;
    height: 100%;
    position: relative;
    overflow-y: auto;

    .board-content {
      position: relative;
      padding: 40px 50px;

      .we-loading-overflow {
        height: calc(100vh - 100px);
      }

      .dashboard-content {
        .dashboard-title {
          font-size: 18px;
          color: var(--text-2);
          margin-bottom: 32px;

          svg {
            width: 1em;
            padding-right: 8px;
          }
        }

        .general-info {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
          gap: 24px;
          margin-bottom: 40px;

          &.flex {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 16px;

            .card {
              margin: 0 24px 24px 0;
            }
          }

          .card {
            display: grid;
            grid-template-columns: 25px 1fr;
            gap: 13px;

            &:last-child {
              margin: 0;
            }

            &.deal {
              .card-icon svg {
                color: var(--primary);
              }
            }

            &.win {
              .card-icon svg {
                color: var(--green);
              }
            }

            &.abandon {
              .card-icon svg {
                color: var(--red);
              }
            }

            &.time {
              .card-icon svg {
                color: var(--yellow);
              }
            }

            &.standby {
              .card-icon svg {
                color: var(--orange);
              }
            }

            .card-icon {
              display: flex;
              justify-content: center;
              padding-top: 4px;
              svg {
                height: 20px;
                width: auto;
              }
            }

            .card-data {
              .card-title {
                font-weight: $medium;
                font-size: 18px;
                color: var(--text-2);
              }

              .card-subtitle {
                color: var(--action-hover);
                font-size: 14px;
                line-height: 15px;
                margin-bottom: 10px;
              }

              .card-info {
                color: var(--text-1);
                font-weight: $medium;
                font-size: 48px;
                line-height: 48px;
                margin-bottom: 6px;
              }

              .card-percentage {
                font-weight: $medium;
                font-size: 14px;
                line-height: 21px;
                color: var(--green);
              }
            }
          }
        }

        .sales-funnel {
          margin-bottom: 60px;
          padding: 32px 40px;
          border: 1px solid var(--line);
          border-radius: 10px;
          max-width: 800px;

          .funnel-labels {
            display: flex;
            align-items: center;
            justify-content: space-around;
            max-width: 600px;
            margin: 0 auto;
            margin-bottom: 32px;
          }

          .funnel {
            margin-bottom: 32px;

            .stage {
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 15px 15px;
              border-radius: 100px;
              border: 1px solid var(--line);
              position: relative;
              margin: 0 auto;
              margin-bottom: 16px;

              .stage-percentage {
                position: absolute;
                bottom: -13px;
                left: 32px;
                background: var(--background-1);
                border: 1px solid var(--line);
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 2px 8px;
                font-size: 12px;
                text-transform: uppercase;
                color: var(--text-2);
                font-weight: $semibold;
              }

              .stage-name {
                max-width: 100%;
                flex: 1;
                font-size: 14px;
                font-weight: $medium;
                text-align: center;
              }

              .stage-labels {
                width: 25%;
                display: flex;
                align-items: center;
                justify-content: space-between;
              }

              .stage-prediction {
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }
          }

          .resume {
            display: flex;
            align-items: center;
            justify-content: center;

            .we-data-field {
              margin: 0 16px;
            }
          }
        }

        .chart-title {
          font-size: 16px;
          color: var(--text-2);
          text-align: center;
          margin-bottom: 24px;
        }

        .deals-line {
          height: 284px;
          margin-bottom: 60px;
          display: grid;
          grid-template-columns: 1fr 460px;
          gap: 20px;

          @media (max-width: 1580px) {
            height: fit-content;
            grid-template-columns: 1fr;
          }

          &.bar-table {
            grid-template-columns: 3fr 1fr;
          }

          .we-table {
            height: 284px;

            &.tasks {
              .table-header,
              .table-content .table-row {
                grid-template-columns: minmax(0, 1fr) 120px !important;
              }
            }

            &.financial {
              .table-header,
              .table-content .table-row {
                grid-template-columns: 54px repeat(3, minmax(0, 1fr)) !important;
              }
            }
          }

          .chart {
            height: 284px;
          }
        }

        .user-charts {
          height: 284px;
          margin-bottom: 60px;

          div {
            height: 100%;
          }
        }

        .pizza-charts {
          display: grid;
          gap: 30px;
          row-gap: 60px;
          grid-template-columns: repeat(auto-fit, minmax(323px, 1fr));
          padding-bottom: 80px;

          .chart-box {
            .chart-grid {
              .doughnut-chart {
                padding: 0 50px;
              }

              .we-table {
                height: fit-content;
                max-height: 210px;
                margin-top: 20px;

                .table-header,
                .table-content .table-row {
                  grid-template-columns: minmax(0px, 1fr) 150px !important;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
