<i18n>
{
  "en": {
    "workflow-of": "Pipeline of",
    "in-the-period-from": "in the period from",
    "to": "to",
    "data-title-1": "Total",
    "data-title-2": "Wons",
    "data-title-3": "Losts",
    "data-title-4": "Won",
    "data-title-5": "Lost",
    "in-the-period": "In the period",
    "average-time": "Average Time",
    "day": "Day",
    "oportunities-by": "Total by",
    "product": "Product",
    "company": "Company",
    "tag": "Tag",
    "lost-reason": "Lost Reason",
    "total": "Total",
    "not-found": "Unable to generate the report",
    "not-found-description": "We noticed you haven't created a pipeline yet. To generate a report you'll need to create one. Shall we start?",
    "not-found-action": "Click here to create",
    "sales-funnel": "Sales funnel",
    "how-many-arrived": "Arrived",
    "how-many-left": "Left",
    "how-many-lost": "Lost",
    "prediction": "Prediction",
    "conversion-rate": "Conversion rate",
    "losts": "Losts",
    "lost-amount": "Lost amount"
  },
  "pt-BR": {
    "workflow-of": "Do pipeline de",
    "in-the-period-from": "no período de",
    "to": "até",
    "data-title-1": "Total",
    "data-title-2": "Conversões",
    "data-title-3": "Abandonos",
    "data-title-4": "Conversão",
    "data-title-5": "Abandono",
    "in-the-period": "No período",
    "average-time": "Tempo médio",
    "day": "Dia",
    "oportunities-by": "Total por",
    "product": "Produto",
    "company": "Empresa",
    "tag": "Etiqueta",
    "lost-reason": "Motivo de Abandono",
    "total": "Total",
    "not-found": "Não foi possível gerar o relatório",
    "not-found-description": "Nós percebemos aqui que você não criou nenhum pipeline ainda. Para gerar o relatório você vai precisar criar um pipeline primeiro. Que tal criar um agora?",
    "not-found-action": "Clique aqui para criar",
    "sales-funnel": "Funil de vendas",
    "how-many-arrived": "Chegaram",
    "how-many-left": "Saíram",
    "how-many-lost": "Perderam",
    "prediction": "Previsão",
    "conversion-rate": "Taxa de conversão",
    "losts": "Perdas",
    "lost-amount": "Valor perdido"
  }
}
</i18n>

<template>
  <div class="board-content">
    <div class="dashboard-content" v-if="pipelines.length > 0">
      <div class="dashboard-title">
        <font-awesome-icon icon="columns" />
        {{ $t("workflow-of") }} <b>{{ pipelineSelected.name }}</b>
        {{ $t("in-the-period-from") }}
        <b>{{ dashboardFilter.from_date | brDateTextWithoutYear }}</b>
        {{ $t("to") }}
        <b>{{ dashboardFilter.to_date | brDateTextWithoutYear }}</b>
      </div>
      <div class="general-info">
        <div class="card deal">
          <div class="card-icon">
            <font-awesome-icon icon="note-sticky" />
          </div>
          <div class="card-data">
            <div class="card-title">
              {{ $t("data-title-1") }}
            </div>
            <div class="card-subtitle">
              {{ $t("in-the-period") }}
            </div>
            <div class="card-info">
              {{ dealsDashboard.total_deals }}
            </div>
          </div>
        </div>
        <div class="card win">
          <div class="card-icon">
            <font-awesome-icon icon="dollar-sign" />
          </div>
          <div class="card-data">
            <div class="card-title">
              {{ $t("data-title-2") }}
            </div>
            <div class="card-subtitle">
              {{ $t("in-the-period") }}
            </div>
            <div class="card-info">
              {{ dealsDashboard.total_wins }}
            </div>
          </div>
        </div>
        <div class="card abandon">
          <div class="card-icon">
            <font-awesome-icon icon="ban" />
          </div>
          <div class="card-data">
            <div class="card-title">
              {{ $t("data-title-3") }}
            </div>
            <div class="card-subtitle">
              {{ $t("in-the-period") }}
            </div>
            <div class="card-info">
              {{ dealsDashboard.total_losses }}
            </div>
          </div>
        </div>
        <div class="card time">
          <div class="card-icon">
            <font-awesome-icon icon="hourglass-half" />
          </div>
          <div class="card-data">
            <div class="card-title">
              {{ $t("data-title-4") }}
            </div>
            <div class="card-subtitle">
              {{ $t("average-time") }}
            </div>
            <div class="card-info">
              {{ dealsDashboard.avarage_time_to_win }}
            </div>
          </div>
        </div>
        <div class="card time">
          <div class="card-icon">
            <font-awesome-icon icon="hourglass-half" />
          </div>
          <div class="card-data">
            <div class="card-title">
              {{ $t("data-title-5") }}
            </div>
            <div class="card-subtitle">
              {{ $t("average-time") }}
            </div>
            <div class="card-info">
              {{ dealsDashboard.avarage_time_to_lose }}
            </div>
          </div>
        </div>
      </div>
      <div class="deals-line">
        <line-chart
          :data="chartdata"
          ref="lineChart"
          class="chart"
        ></line-chart>
        <we-table
          :labels="[
            $t('day'),
            $t('data-title-1'),
            $t('data-title-2'),
            $t('data-title-3')
          ]"
          :columns="[
            lineChartLabels,
            lineChartDealData,
            lineChartWinData,
            lineChartLossData
          ]"
        />
      </div>
      <div class="user-charts">
        <bar-chart :data="bardata" ref="barChart"></bar-chart>
      </div>
      <div class="pizza-charts">
        <div class="chart-box">
          <div class="chart-title">
            {{ $t("oportunities-by") }} {{ $t("product") }}
          </div>
          <div class="chart-grid">
            <doughnut-chart
              class="doughnut-chart"
              :data="dealsPerProductChartData"
              ref="dealsPerProductChart"
            ></doughnut-chart>
            <we-table
              :labels="[$t('product'), $t('total')]"
              :columns="[dealPerProductChartLabels, dealPerProductChartData]"
              :colors="dealPerProductChartColors"
            />
          </div>
        </div>
        <div class="chart-box">
          <div class="chart-title">
            {{ $t("oportunities-by") }} {{ $t("company") }}
          </div>
          <div class="chart-grid">
            <doughnut-chart
              class="doughnut-chart"
              :data="dealsPerCompanyChartData"
              ref="dealsPerCompanyChart"
            ></doughnut-chart>
            <we-table
              :labels="[$t('company'), $t('total')]"
              :columns="[dealPerCompanyChartLabels, dealPerCompanyChartData]"
              :colors="dealPerCompanyChartColors"
            />
          </div>
        </div>
        <div class="chart-box">
          <div class="chart-title">
            {{ $t("oportunities-by") }} {{ $t("tag") }}
          </div>
          <div class="chart-grid">
            <doughnut-chart
              class="doughnut-chart"
              :data="dealsPerTagChartData"
              ref="dealsPerTagChart"
            ></doughnut-chart>
            <we-table
              :labels="[$t('tag'), $t('total')]"
              :columns="[dealPerTagChartLabels, dealPerTagChartData]"
              :colors="dealPerTagChartColors"
            />
          </div>
        </div>
        <div class="chart-box">
          <div class="chart-title">
            {{ $t("oportunities-by") }} {{ $t("lost-reason") }}
          </div>
          <div class="chart-grid">
            <doughnut-chart
              class="doughnut-chart"
              :data="dealsPerLostReasonChartData"
              ref="dealsPerLostReasonChart"
            ></doughnut-chart>
            <we-table
              :labels="[$t('lost-reason'), $t('total')]"
              :columns="[
                dealPerLostReasonChartLabels,
                dealPerLostReasonChartData
              ]"
              :colors="dealPerLostReasonChartColors"
            />
          </div>
        </div>
      </div>
      <div class="dashboard-title">
        <font-awesome-icon icon="filter-circle-dollar" />
        {{ $t("sales-funnel") }}
      </div>
      <div class="sales-funnel" v-if="dealsDashboard.funnel">
        <div class="funnel-labels">
          <we-label class="rounded-pill gray" :text="$t('how-many-arrived')" />
          <we-label class="rounded-pill primary" :text="$t('how-many-left')" />
          <we-label class="rounded-pill error" :text="$t('how-many-lost')" />
          <we-label class="rounded-pill success" :text="$t('prediction')" />
        </div>
        <div class="funnel">
          <div
            class="stage"
            v-for="(stage, i) in funnelWithoutAbandonStage"
            :key="i"
            :style="{ width: `${i === 0 ? 100 : 100 - i * 3}%` }"
          >
            <div class="rounded-pill stage-percentage">
              {{ Math.trunc(stage.conversion_rate) }}%
            </div>
            <we-text-tooltip class="stage-name" :text="stage.stage" />
            <div class="stage-labels">
              <we-label
                class="rounded-pill gray"
                :text="stage.total_deals_to_stage"
              />
              <we-label
                class="rounded-pill primary"
                :text="stage.total_deals_from_stage"
              />
              <we-label class="rounded-pill error" :text="stage.losses" />
            </div>
            <div class="stage-prediction">
              <we-label
                class="rounded-pill success"
                :text="$options.filters.valueToReais(stage.amount)"
              />
            </div>
          </div>
        </div>
        <div class="resume">
          <we-data-field
            class="dashboard"
            :label="$t('conversion-rate')"
            :value="`${lastStageConversionRate(funnelWithoutAbandonStage)}%`"
          />
          <we-data-field
            class="dashboard error--text"
            :label="$t('losts')"
            :value="abandonStage.total_deals_to_stage"
          />
          <we-data-field
            class="dashboard error--text"
            :label="$t('lost-amount')"
            :value="$options.filters.valueToReais(abandonStage.amount)"
          />
        </div>
      </div>
    </div>
    <we-loading-overflow :loading="loading" />
    <we-not-found v-if="!loading && (!pipelines || pipelines.length === 0)">
      <template #title> {{ $t("not-found") }} </template>
      <template #description>
        {{ $t("not-found-description") }}
        <router-link
          :to="{ name: 'pipelinesConfigs' }"
          class="primary--text"
          active-class="active"
        >
          {{ $t("not-found-action") }}
        </router-link>
      </template>
      <template #picture>
        <img
          src="@/assets/undraw/dashboard.svg"
          alt="Onboarding"
          width="299"
          height="254"
        />
      </template>
    </we-not-found>
  </div>
</template>

<script>
import LineChart from "@/components/charts/Line.vue";
import BarChart from "@/components/charts/Bar.vue";
import DoughnutChart from "@/components/charts/Doughnut.vue";
import { mapActions } from "vuex";
export default {
  components: {
    LineChart,
    BarChart,
    DoughnutChart
  },
  data() {
    return {
      loading: true,
      dialogFilter: false
    };
  },
  computed: {
    abandonStage() {
      return this.funnel[this.funnel.length - 1];
    },
    funnel() {
      return this.dealsDashboard?.funnel || [];
    },
    funnelWithoutAbandonStage() {
      return this.funnel.filter((e, i) => i !== this.funnel.length - 1);
    },
    dealsDashboard() {
      return this.$store.getters.getDealsDashboard;
    },
    dashboardFilter() {
      return this.$store.getters.getDashboardFilter;
    },
    pipelineSelected() {
      return (
        this.pipelines.find(e => e.id === this.dashboardFilter.pipeline_id) ||
        {}
      );
    },
    loggedUser() {
      return this.$store.getters.getLoggedUser;
    },
    pipelines() {
      return this.loggedUser?.pipelines?.length > 0
        ? this.$store.getters.getPipelines.filter(e =>
            this.loggedUser?.pipelines?.includes(e.id)
          )
        : this.$store.getters.getPipelines;
    },
    lineChartLabels() {
      return this.dealsDashboard?.lineChart?.labels || [];
    },
    lineChartDealData() {
      return this.dealsDashboard?.lineChart?.dealData || [];
    },
    lineChartWinData() {
      return this.dealsDashboard?.lineChart?.winData || [];
    },
    lineChartLossData() {
      return this.dealsDashboard?.lineChart?.lossData || [];
    },
    dealPerProductChartLabels() {
      return this.dealsDashboard.dealsPerProduct?.labels || [];
    },
    dealPerProductChartColors() {
      return this.dealsDashboard.dealsPerProduct?.colors || [];
    },
    dealPerProductChartData() {
      return this.mapArrayWithPercentage(
        this.dealsDashboard.dealsPerProduct?.data
      );
    },
    dealPerCompanyChartLabels() {
      return this.dealsDashboard.dealsPerCompany?.labels || [];
    },
    dealPerCompanyChartColors() {
      return this.dealsDashboard.dealsPerCompany?.colors || [];
    },
    dealPerCompanyChartData() {
      return this.mapArrayWithPercentage(
        this.dealsDashboard.dealsPerCompany?.data
      );
    },
    dealPerTagChartLabels() {
      return this.dealsDashboard.dealsPerTag?.labels || [];
    },
    dealPerTagChartColors() {
      return this.dealsDashboard.dealsPerTag?.colors || [];
    },
    dealPerTagChartData() {
      return this.mapArrayWithPercentage(this.dealsDashboard.dealsPerTag?.data);
    },
    dealPerLostReasonChartLabels() {
      return this.dealsDashboard.dealsPerLostReason?.labels || [];
    },
    dealPerLostReasonChartColors() {
      return this.dealsDashboard.dealsPerLostReason?.colors || [];
    },
    dealPerLostReasonChartData() {
      return this.mapArrayWithPercentage(
        this.dealsDashboard.dealsPerLostReason?.data
      );
    },
    chartdata() {
      return {
        labels: this.dealsDashboard?.lineChart?.labels,
        datasets: [
          {
            label: this.$t("data-title-1"),
            backgroundColor: "rgba(0, 0, 0, 0)",
            borderColor: "#0C80F2",
            pointBorderWidth: 3,
            data: this.dealsDashboard?.lineChart?.dealData,
            pointBackgroundColor: "#0C80F2"
          },
          {
            label: this.$t("data-title-2"),
            backgroundColor: "rgba(0, 0, 0, 0)",
            borderColor: "#25b660",
            pointBorderWidth: 3,
            data: this.dealsDashboard?.lineChart?.winData,
            pointBackgroundColor: "#25b660"
          },
          {
            label: this.$t("data-title-3"),
            backgroundColor: "rgba(0, 0, 0, 0)",
            borderColor: "#FF4757",
            pointBorderWidth: 3,
            data: this.dealsDashboard?.lineChart?.lossData,
            pointBackgroundColor: "#FF4757"
          }
        ]
      };
    },
    bardata() {
      return {
        labels: this.dealsDashboard.barChart?.labels,
        datasets: [
          {
            label: this.$t("data-title-1"),
            backgroundColor: "#ABBDFC",
            data: this.dealsDashboard.barChart?.dealData
          },
          {
            label: this.$t("data-title-2"),
            backgroundColor: "#92DBB0",
            data: this.dealsDashboard.barChart?.winData
          },
          {
            label: this.$t("data-title-3"),
            backgroundColor: "#FFA3AB",
            data: this.dealsDashboard.barChart?.lossData
          }
        ]
      };
    },
    dealsPerProductChartData() {
      return {
        labels: this.dealsDashboard.dealsPerProduct?.labels,
        datasets: [
          {
            backgroundColor: this.dealsDashboard.dealsPerProduct?.colors,
            data: this.dealsDashboard.dealsPerProduct?.data
          }
        ]
      };
    },
    dealsPerCompanyChartData() {
      return {
        labels: this.dealsDashboard.dealsPerCompany?.labels,
        datasets: [
          {
            backgroundColor: this.dealsDashboard.dealsPerCompany?.colors,
            data: this.dealsDashboard.dealsPerCompany?.data
          }
        ]
      };
    },
    dealsPerTagChartData() {
      return {
        labels: this.dealsDashboard.dealsPerTag?.labels,
        datasets: [
          {
            backgroundColor: this.dealsDashboard.dealsPerTag?.colors,
            data: this.dealsDashboard.dealsPerTag?.data
          }
        ]
      };
    },
    dealsPerLostReasonChartData() {
      return {
        labels: this.dealsDashboard.dealsPerLostReason?.labels,
        datasets: [
          {
            backgroundColor: this.dealsDashboard.dealsPerLostReason?.colors,
            data: this.dealsDashboard.dealsPerLostReason?.data
          }
        ]
      };
    }
  },
  methods: {
    ...mapActions(["dealsDashboardRequest", "pipelinesRequest"]),
    lastStageConversionRate(funnel) {
      return Math.trunc(funnel[funnel.length - 1]?.conversion_rate || 0);
    },
    async doRequests() {
      this.loading = true;
      await this.dealsDashboardRequest();
      this.$refs.lineChart?.render();
      this.$refs.barChart?.render();
      this.$refs.dealsPerProductChart?.render();
      this.$refs.dealsPerCompanyChart?.render();
      this.$refs.dealsPerTagChart?.render();
      this.$refs.dealsPerLostReasonChart?.render();
      this.loading = false;
    },
    mapArrayWithPercentage(array) {
      let total = 0;
      let aux = [];

      if (array) {
        array.forEach(e => {
          total += Number(e);
        });

        aux = array.map(e => {
          const percentage = Math.round((e * 100) / total) + "%";

          return `${e} (${percentage})`;
        });
      }

      return aux;
    }
  },
  async mounted() {
    if (this.pipelines.length <= 0) {
      await this.pipelinesRequest();
    }

    const today = new Date();
    const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);

    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit"
    };

    const payload = {
      pipeline_id: this.pipelines[0]?.id,
      from_date: firstDay.toLocaleString("fr-CA", options),
      to_date: today.toLocaleString("fr-CA", options)
    };

    if (!this.dashboardFilter.pipeline_id) {
      this.$store.commit("setDashboardFilter", payload);
    }

    if (payload.pipeline_id) {
      this.doRequests();
    } else {
      this.loading = false;
    }
  }
};
</script>

<style></style>
