<i18n>
{
  "en": {
    "workflow-of": "Pipeline of",
    "in-the-period-from": "in the period from",
    "to": "to",
    "data-title-1": "Total",
    "data-title-2": "Amount",
    "data-title-3": "Losts",
    "data-title-4": "Won",
    "data-title-5": "Lost",
    "in-the-period": "In the period",
    "average-time": "Average Time",
    "day": "Day",
    "amount-by": "Amount by",
    "product": "Product",
    "company": "Company",
    "tag": "Tag",
    "user": "User",
    "total": "Total",
    "not-found": "Unable to generate the report",
    "not-found-description": "We noticed you haven't created a pipeline yet. To generate a report you'll need to create one. Shall we start?",
    "not-found-action": "Click here to create"
  },
  "pt-BR": {
    "workflow-of": "Do pipeline de",
    "in-the-period-from": "no período de",
    "to": "até",
    "data-title-1": "Total",
    "data-title-2": "Montante",
    "data-title-3": "Abandonos",
    "data-title-4": "Conversão",
    "data-title-5": "Abandono",
    "in-the-period": "No período",
    "average-time": "Tempo médio",
    "day": "Dia",
    "amount-by": "Montante por",
    "product": "Produto",
    "company": "Empresa",
    "tag": "Etiqueta",
    "user": "Usuário",
    "total": "Total",
    "not-found": "Não foi possível gerar o relatório",
    "not-found-description": "Nós percebemos aqui que você não criou nenhum pipeline ainda. Para gerar o relatório você vai precisar criar um pipeline primeiro. Que tal criar um agora?",
    "not-found-action": "Clique aqui para criar"
  }
}
</i18n>

<template>
  <div class="board-content">
    <div class="dashboard-content" v-if="pipelines.length > 0">
      <div class="dashboard-title">
        <font-awesome-icon icon="columns" />
        {{ $t("workflow-of") }} <b>{{ pipelineSelected.name }}</b>
        {{ $t("in-the-period-from") }}
        <b>{{ dashboardFilter.from_date | brDateTextWithoutYear }}</b>
        {{ $t("to") }}
        <b>{{ dashboardFilter.to_date | brDateTextWithoutYear }}</b>
      </div>
      <div class="general-info flex">
        <div class="card deal">
          <div class="card-icon">
            <font-awesome-icon icon="note-sticky" />
          </div>
          <div class="card-data">
            <div class="card-title">
              {{ $t("data-title-1") }}
            </div>
            <div class="card-subtitle">
              {{ $t("in-the-period") }}
            </div>
            <div class="card-info">
              {{ salesDashboard.total_deals }}
            </div>
          </div>
        </div>
        <div class="card win">
          <div class="card-icon">
            <font-awesome-icon icon="dollar-sign" />
          </div>
          <div class="card-data">
            <div class="card-title">
              {{ $t("data-title-2") }}
            </div>
            <div class="card-subtitle">
              {{ $t("in-the-period") }}
            </div>
            <div class="card-info">
              {{ salesDashboard.amount | valueToReais }}
            </div>
          </div>
        </div>
        <div class="card abandon">
          <div class="card-icon">
            <font-awesome-icon icon="ban" />
          </div>
          <div class="card-data">
            <div class="card-title">
              {{ $t("data-title-3") }}
            </div>
            <div class="card-subtitle">
              {{ $t("in-the-period") }}
            </div>
            <div class="card-info">
              {{ salesDashboard.total_losses }}
            </div>
          </div>
        </div>
        <div class="card time">
          <div class="card-icon">
            <font-awesome-icon icon="hourglass-half" />
          </div>
          <div class="card-data">
            <div class="card-title">
              {{ $t("data-title-4") }}
            </div>
            <div class="card-subtitle">
              {{ $t("average-time") }}
            </div>
            <div class="card-info">
              {{ salesDashboard.avarage_time_to_win }}
            </div>
          </div>
        </div>
        <div class="card time">
          <div class="card-icon">
            <font-awesome-icon icon="hourglass-half" />
          </div>
          <div class="card-data">
            <div class="card-title">
              {{ $t("data-title-5") }}
            </div>
            <div class="card-subtitle">
              {{ $t("average-time") }}
            </div>
            <div class="card-info">
              {{ salesDashboard.avarage_time_to_lose }}
            </div>
          </div>
        </div>
      </div>
      <div class="deals-line">
        <line-chart
          :data="chartdata"
          ref="lineChart"
          class="chart"
        ></line-chart>
        <we-table
          :labels="[$t('day'), `${$t('amount-by')} ${$t('day')}`]"
          :columns="[lineChartLabels, lineChartAmountData]"
        />
      </div>
      <div class="pizza-charts">
        <div class="chart-box">
          <div class="chart-title">
            {{ $t("amount-by") }} {{ $t("product") }}
          </div>
          <div class="chart-grid">
            <doughnut-chart
              class="doughnut-chart"
              :data="amountsPerProductChartData"
              ref="amountPerProductChart"
            ></doughnut-chart>
            <we-table
              :labels="[$t('product'), $t('total')]"
              :columns="[
                amountPerProductChartLabels,
                amountPerProductChartData
              ]"
              :colors="amountPerProductChartColors"
            />
          </div>
        </div>
        <div class="chart-box">
          <div class="chart-title">
            {{ $t("amount-by") }} {{ $t("company") }}
          </div>
          <div class="chart-grid">
            <doughnut-chart
              class="doughnut-chart"
              :data="amountsPerCompanyChartData"
              ref="amountPerCompanyChart"
            ></doughnut-chart>
            <we-table
              :labels="[$t('company'), $t('total')]"
              :columns="[
                amountPerCompanyChartLabels,
                amountPerCompanyChartData
              ]"
              :colors="amountPerCompanyChartColors"
            />
          </div>
        </div>
        <div class="chart-box">
          <div class="chart-title">{{ $t("amount-by") }} {{ $t("tag") }}</div>
          <div class="chart-grid">
            <doughnut-chart
              class="doughnut-chart"
              :data="amountsPerTagChartData"
              ref="amountPerTagChart"
            ></doughnut-chart>
            <we-table
              :labels="[$t('tag'), $t('total')]"
              :columns="[amountPerTagChartLabels, amountPerTagChartData]"
              :colors="amountPerTagChartColors"
            />
          </div>
        </div>
        <div class="chart-box">
          <div class="chart-title">{{ $t("amount-by") }} {{ $t("user") }}</div>
          <div class="chart-grid">
            <doughnut-chart
              class="doughnut-chart"
              :data="amountsPerUserChartData"
              ref="amountPerUserChart"
            ></doughnut-chart>
            <we-table
              :labels="[$t('user'), $t('total')]"
              :columns="[amountPerUserChartLabels, amountPerUserChartData]"
              :colors="amountPerUserChartColors"
            />
          </div>
        </div>
      </div>
    </div>
    <we-loading-overflow :loading="loading" />
    <we-not-found v-if="!loading && (!pipelines || pipelines.length === 0)">
      <template #title> {{ $t("not-found") }} </template>
      <template #description>
        {{ $t("not-found-description") }}
        <router-link
          :to="{ name: 'pipelinesConfigs' }"
          class="primary--text"
          active-class="active"
        >
          {{ $t("not-found-action") }}
        </router-link>
      </template>
      <template #picture>
        <img
          src="@/assets/undraw/dashboard.svg"
          alt="Onboarding"
          width="299"
          height="254"
        />
      </template>
    </we-not-found>
  </div>
</template>

<script>
import LineChart from "@/components/charts/Line.vue";
import DoughnutChart from "@/components/charts/Doughnut.vue";
import { mapActions } from "vuex";
export default {
  components: {
    LineChart,
    DoughnutChart
  },
  data() {
    return {
      loading: true,
      dialogFilter: false
    };
  },
  computed: {
    dealsDashboard() {
      return this.$store.getters.getDealsDashboard;
    },
    salesDashboard() {
      return this.$store.getters.getSalesDashboard;
    },
    dashboardFilter() {
      return this.$store.getters.getDashboardFilter;
    },
    pipelineSelected() {
      return (
        this.pipelines.find(e => e.id === this.dashboardFilter.pipeline_id) ||
        {}
      );
    },
    pipelines() {
      return this.$store.getters.getPipelines;
    },
    lineChartLabels() {
      return this.salesDashboard?.lineChart?.labels || [];
    },
    lineChartAmountData() {
      return this.salesDashboard?.lineChart?.amountData || [];
    },
    amountPerProductChartLabels() {
      return this.salesDashboard.amountPerProduct?.labels || [];
    },
    amountPerProductChartColors() {
      return this.salesDashboard.amountPerProduct?.colors || [];
    },
    amountPerProductChartData() {
      return this.mapArrayWithPercentage(
        this.salesDashboard.amountPerProduct?.data
      );
    },
    amountPerCompanyChartLabels() {
      return this.salesDashboard.amountPerCompany?.labels || [];
    },
    amountPerCompanyChartColors() {
      return this.salesDashboard.amountPerCompany?.colors || [];
    },
    amountPerCompanyChartData() {
      return this.mapArrayWithPercentage(
        this.salesDashboard.amountPerCompany?.data
      );
    },
    amountPerTagChartLabels() {
      return this.salesDashboard.amountPerTag?.labels || [];
    },
    amountPerTagChartColors() {
      return this.salesDashboard.amountPerTag?.colors || [];
    },
    amountPerTagChartData() {
      return this.mapArrayWithPercentage(
        this.salesDashboard.amountPerTag?.data
      );
    },
    amountPerUserChartLabels() {
      return this.salesDashboard.amountPerUser?.labels || [];
    },
    amountPerUserChartColors() {
      return this.salesDashboard.amountPerUser?.colors || [];
    },
    amountPerUserChartData() {
      return this.mapArrayWithPercentage(
        this.salesDashboard.amountPerUser?.data
      );
    },
    chartdata() {
      return {
        labels: this.salesDashboard?.lineChart?.labels,
        datasets: [
          {
            label: `${this.$t("amount-by")} ${this.$t("day")}`,
            backgroundColor: "rgba(0, 0, 0, 0)",
            borderColor: "#0C80F2",
            pointBorderWidth: 3,
            data: this.salesDashboard?.lineChart?.amountData,
            pointBackgroundColor: "#0C80F2"
          }
        ]
      };
    },
    amountsPerProductChartData() {
      return {
        labels: this.salesDashboard.amountPerProduct?.labels,
        datasets: [
          {
            backgroundColor: this.salesDashboard.amountPerProduct?.colors,
            data: this.salesDashboard.amountPerProduct?.data
          }
        ]
      };
    },
    amountsPerCompanyChartData() {
      return {
        labels: this.salesDashboard.amountPerCompany?.labels,
        datasets: [
          {
            backgroundColor: this.salesDashboard.amountPerCompany?.colors,
            data: this.salesDashboard.amountPerCompany?.data
          }
        ]
      };
    },
    amountsPerTagChartData() {
      return {
        labels: this.salesDashboard.amountPerTag?.labels,
        datasets: [
          {
            backgroundColor: this.salesDashboard.amountPerTag?.colors,
            data: this.salesDashboard.amountPerTag?.data
          }
        ]
      };
    },
    amountsPerUserChartData() {
      return {
        labels: this.salesDashboard.amountPerUser?.labels,
        datasets: [
          {
            backgroundColor: this.salesDashboard.amountPerUser?.colors,
            data: this.salesDashboard.amountPerUser?.data
          }
        ]
      };
    }
  },
  methods: {
    ...mapActions(["pipelinesRequest", "salesDashboardRequest"]),
    async doRequests() {
      this.loading = true;
      await this.salesDashboardRequest();
      this.$refs.lineChart?.render();
      this.$refs.amountPerProductChart?.render();
      this.$refs.amountPerCompanyChart?.render();
      this.$refs.amountPerTagChart?.render();
      this.$refs.amountPerUserChart?.render();
      this.loading = false;
    },
    mapArrayWithPercentage(array) {
      let total = 0;
      let aux = [];

      if (array) {
        array.forEach(e => {
          total += Number(e);
        });

        aux = array.map(e => {
          const percentage = Math.round((e * 100) / total) + "%";

          return `${e} (${percentage})`;
        });
      }

      return aux;
    }
  },
  async mounted() {
    if (this.pipelines.length <= 0) {
      await this.pipelinesRequest();
    }

    const today = new Date();
    const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);

    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit"
    };

    const payload = {
      pipeline_id: this.pipelines[0]?.id,
      from_date: firstDay.toLocaleString("fr-CA", options),
      to_date: today.toLocaleString("fr-CA", options)
    };

    if (!this.dashboardFilter.pipeline_id) {
      this.$store.commit("setDashboardFilter", payload);
    }

    if (payload.pipeline_id) {
      this.doRequests();
    } else {
      this.loading = false;
    }
  }
};
</script>

<style></style>
