<i18n>
{
  "en": {
    "title": "Filter dashboard",
    "input-1": "Pipeline",
    "input-2": "From",
    "input-3": "To",
    "placeholder-1": "Select a pipeline",
    "save": "Save updates"
  },
  "pt-BR": {
    "title": "Filtrar dashboard",
    "input-1": "Pipeline",
    "input-2": "De",
    "input-3": "Até",
    "placeholder-1": "Selecione um pipeline",
    "save": "Salvar alterações"
  }
}
</i18n>

<template>
  <modal-action @close="closeDashboardFilter" :columns="1">
    <template #view>
      <div class="modal">
        <div class="modal-header">
          <div class="name">
            <div class="icon">
              <font-awesome-icon icon="sliders-h" />
            </div>
            {{ $t("title") }}
          </div>
          <div class="options">
            <we-icon-button icon="times" @click="closeDashboardFilter" />
          </div>
        </div>
        <div class="modal-body">
          <div class="fields">
            <we-input
              v-if="showPipelineInput"
              type="global-select"
              :label="$t('input-1')"
              :placeholder="$t('placeholder-1')"
              v-model="filter.pipeline_id"
              :items="pipelineItems"
            />
            <we-input
              type="date"
              :label="$t('input-2')"
              v-model="filter.from_date"
              :maxDate="todayDate"
              :clearable="false"
            />
            <we-input
              type="date"
              :label="$t('input-3')"
              v-model="filter.to_date"
              :maxDate="todayDate"
              :clearable="false"
            />
          </div>
        </div>
        <div class="bottom">
          <we-button
            class="green"
            :text="$t('save')"
            icon="check"
            :loading="btnLoading"
            @click="doFilter"
          />
        </div>
        <we-loading-overflow :loading="loading" />
      </div>
    </template>
  </modal-action>
</template>

<script>
export default {
  data() {
    return {
      showPipelineInput: true,
      btnLoading: false,
      loading: true,
      filter: {
        pipeline_id: 0,
        from_date: "",
        to_date: ""
      }
    };
  },
  computed: {
    loggedUser() {
      return this.$store.getters.getLoggedUser;
    },
    pipelines() {
      return this.loggedUser?.pipelines?.length > 0
        ? this.$store.getters.getPipelines.filter(e =>
            this.loggedUser?.pipelines?.includes(e.id)
          )
        : this.$store.getters.getPipelines;
    },
    pipelineItems() {
      return this.pipelines.map(e => {
        return {
          text: e.name,
          value: e.id
        };
      });
    },
    todayDate() {
      return new Date().toLocaleString("fr-CA", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit"
      });
    }
  },
  methods: {
    closeDashboardFilter() {
      this.$emit("doFilter", false);
    },
    async doFilter() {
      this.$store.commit("setDashboardFilter", this.filter);
      this.$emit("doFilter", true);
    }
  },
  async mounted() {
    if (["dashboardTasks", "dashboardFinancial"].includes(this.$route.name)) {
      this.showPipelineInput = false;
    }

    this.filter = this.$store.getters.getDashboardFilter;
    this.loading = false;
  }
};
</script>
