<i18n>
{
  "en": {
    "in-the-period-from": "In the period from",
    "to": "to",
    "data-title-1": "Revenue",
    "data-title-2": "Expense",
    "data-title-3": "Balance",
    "data-title-4": "Paying customers",
    "data-title-5": "Defaulting customers",
    "in-the-period": "In the period",
    "month": "Month",
    "amount-by": "Amount by",
    "cost-center": "Cost center",
    "type": "Type",
    "method": "Method",
    "status": "Status",
    "total": "Total",
    "revenue": "Revenue",
    "expense": "Expense",
    "not-found": "Unable to generate the report",
    "not-found-description": "We noticed you haven't created a pipeline yet. To generate a report you'll need to create one. Shall we start?",
    "not-found-action": "Click here to create",
    "yearly-results": "Yearly results"
  },
  "pt-BR": {
    "in-the-period-from": "No período de",
    "to": "até",
    "data-title-1": "Contas a receber",
    "data-title-2": "Contas a pagar",
    "data-title-3": "Saldo",
    "data-title-4": "Pagantes",
    "data-title-5": "Desistentes",
    "in-the-period": "No período",
    "month": "Mês",
    "amount-by": "Montante por",
    "cost-center": "Centro de custo",
    "type": "Tipo",
    "method": "Método",
    "status": "Status",
    "total": "Total",
    "revenue": "A receber",
    "expense": "A pagar",
    "not-found": "Não foi possível gerar o relatório",
    "not-found-description": "Nós percebemos aqui que você não criou nenhum pipeline ainda. Para gerar o relatório você vai precisar criar um pipeline primeiro. Que tal criar um agora?",
    "not-found-action": "Clique aqui para criar",
    "yearly-results": "Resultados anuais"
  }
}
</i18n>

<template>
  <div class="board-content">
    <div class="dashboard-content" v-if="pipelines.length > 0">
      <div class="dashboard-title">
        <font-awesome-icon icon="calendar-days" />
        {{ $t("in-the-period-from") }}
        <b>{{ dashboardFilter.from_date | brDateTextWithoutYear }}</b>
        {{ $t("to") }}
        <b>{{ dashboardFilter.to_date | brDateTextWithoutYear }}</b>
      </div>
      <div class="general-info flex">
        <div class="card win">
          <div class="card-icon">
            <font-awesome-icon icon="arrow-down" />
          </div>
          <div class="card-data">
            <div class="card-title">
              {{ $t("data-title-1") }}
            </div>
            <div class="card-subtitle">
              {{ $t("in-the-period") }}
            </div>
            <div class="card-info">
              {{ financialDashboard.total_revenue | valueToReais }}
            </div>
          </div>
        </div>
        <div class="card abandon">
          <div class="card-icon">
            <font-awesome-icon icon="arrow-up" />
          </div>
          <div class="card-data">
            <div class="card-title">
              {{ $t("data-title-2") }}
            </div>
            <div class="card-subtitle">
              {{ $t("in-the-period") }}
            </div>
            <div class="card-info">
              {{ financialDashboard.total_expense | valueToReais }}
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-icon primary--text">
            <font-awesome-icon icon="dollar-sign" />
          </div>
          <div class="card-data">
            <div class="card-title">
              {{ $t("data-title-3") }}
            </div>
            <div class="card-subtitle">
              {{ $t("in-the-period") }}
            </div>
            <div class="card-info">
              {{ financialDashboard.balance | valueToReais }}
            </div>
          </div>
        </div>
        <div class="card time">
          <div class="card-icon">
            <font-awesome-icon icon="hourglass-half" />
          </div>
          <div class="card-data">
            <div class="card-title">
              {{ $t("data-title-4") }}
            </div>
            <div class="card-subtitle">
              {{ $t("in-the-period") }}
            </div>
            <div class="card-info">
              {{ financialDashboard.total_paying_customers }}
            </div>
          </div>
        </div>
        <div class="card abandon">
          <div class="card-icon">
            <font-awesome-icon icon="ban" />
          </div>
          <div class="card-data">
            <div class="card-title">
              {{ $t("data-title-5") }}
            </div>
            <div class="card-subtitle">
              {{ $t("in-the-period") }}
            </div>
            <div class="card-info">
              {{ financialDashboard.total_defaulting_customers }}
            </div>
          </div>
        </div>
      </div>
      <div class="pizza-charts">
        <div class="chart-box">
          <div class="chart-title">
            {{ $t("amount-by") }} {{ $t("cost-center") }}
          </div>
          <div class="chart-grid">
            <doughnut-chart
              class="doughnut-chart"
              :data="amountsPerCostCenterChartData"
              ref="amountPerCostCenterChart"
            ></doughnut-chart>
            <we-table
              :labels="[$t('cost-center'), $t('total')]"
              :columns="[
                amountPerCostCenterChartLabels,
                amountPerCostCenterChartData
              ]"
              :colors="amountPerCostCenterChartColors"
            />
          </div>
        </div>
        <div class="chart-box">
          <div class="chart-title">{{ $t("amount-by") }} {{ $t("type") }}</div>
          <div class="chart-grid">
            <doughnut-chart
              class="doughnut-chart"
              :data="amountsPerTypeChartData"
              ref="amountPerTypeChart"
            ></doughnut-chart>
            <we-table
              :labels="[$t('type'), $t('total')]"
              :columns="[amountPerTypeChartLabels, amountPerTypeChartData]"
              :colors="amountPerTypeChartColors"
            />
          </div>
        </div>
        <div class="chart-box">
          <div class="chart-title">
            {{ $t("amount-by") }} {{ $t("method") }}
          </div>
          <div class="chart-grid">
            <doughnut-chart
              class="doughnut-chart"
              :data="amountsPerMethodChartData"
              ref="amountPerMethodChart"
            ></doughnut-chart>
            <we-table
              :labels="[$t('method'), $t('total')]"
              :columns="[amountPerMethodChartLabels, amountPerMethodChartData]"
              :colors="amountPerMethodChartColors"
            />
          </div>
        </div>
        <div class="chart-box">
          <div class="chart-title">
            {{ $t("amount-by") }} {{ $t("status") }}
          </div>
          <div class="chart-grid">
            <doughnut-chart
              class="doughnut-chart"
              :data="amountsPerStatusChartData"
              ref="amountPerStatusChart"
            ></doughnut-chart>
            <we-table
              :labels="[$t('status'), $t('total')]"
              :columns="[amountPerStatusChartLabels, amountPerStatusChartData]"
              :colors="amountPerStatusChartColors"
            />
          </div>
        </div>
      </div>
      <div class="dashboard-title">
        <font-awesome-icon icon="award" />
        {{ $t("yearly-results") }}
      </div>
      <div class="deals-line bar-table">
        <div class="user-charts" v-if="financialDashboard.barChart">
          <bar-chart :data="bardata" ref="barChart"></bar-chart>
        </div>
        <we-table
          class="financial"
          :labels="[
            $t('month'),
            $t('revenue'),
            $t('expense'),
            $t('data-title-3')
          ]"
          :columns="[tableLabels, tableRevenue, tableExpense, tableBalance]"
        />
      </div>
    </div>
    <we-loading-overflow :loading="loading" />
    <we-not-found v-if="!loading && (!pipelines || pipelines.length === 0)">
      <template #title> {{ $t("not-found") }} </template>
      <template #description>
        {{ $t("not-found-description") }}
        <router-link
          :to="{ name: 'pipelinesConfigs' }"
          class="primary--text"
          active-class="active"
        >
          {{ $t("not-found-action") }}
        </router-link>
      </template>
      <template #picture>
        <img
          src="@/assets/undraw/dashboard.svg"
          alt="Onboarding"
          width="299"
          height="254"
        />
      </template>
    </we-not-found>
  </div>
</template>

<script>
import DoughnutChart from "@/components/charts/Doughnut.vue";
import BarChart from "@/components/charts/Bar.vue";
import { mapActions } from "vuex";
export default {
  components: {
    DoughnutChart,
    BarChart
  },
  data() {
    return {
      loading: true,
      dialogFilter: false
    };
  },
  computed: {
    dealsDashboard() {
      return this.$store.getters.getDealsDashboard;
    },
    financialDashboard() {
      return this.$store.getters.getFinancialDashboard;
    },
    dashboardFilter() {
      return this.$store.getters.getDashboardFilter;
    },
    pipelineSelected() {
      return (
        this.pipelines.find(e => e.id === this.dashboardFilter.pipeline_id) ||
        {}
      );
    },
    pipelines() {
      return this.$store.getters.getPipelines;
    },
    tableLabels() {
      if (!this.financialDashboard?.table?.labels) return [];

      return this.financialDashboard?.table?.labels.map(e => {
        return e.slice(0, 3);
      });
    },
    tableRevenue() {
      return this.financialDashboard?.table?.revenue || [];
    },
    tableExpense() {
      return this.financialDashboard?.table?.expense || [];
    },
    tableBalance() {
      return this.financialDashboard?.table?.balance || [];
    },
    amountPerCostCenterChartLabels() {
      return this.financialDashboard.amountPerCostCenter?.labels || [];
    },
    amountPerCostCenterChartColors() {
      return this.financialDashboard.amountPerCostCenter?.colors || [];
    },
    amountPerCostCenterChartData() {
      return this.mapArrayWithPercentage(
        this.financialDashboard.amountPerCostCenter?.data
      );
    },
    amountPerTypeChartLabels() {
      return this.financialDashboard.amountPerType?.labels || [];
    },
    amountPerTypeChartColors() {
      return this.financialDashboard.amountPerType?.colors || [];
    },
    amountPerTypeChartData() {
      return this.mapArrayWithPercentage(
        this.financialDashboard.amountPerType?.data
      );
    },
    amountPerMethodChartLabels() {
      return this.financialDashboard.amountPerMethod?.labels || [];
    },
    amountPerMethodChartColors() {
      return this.financialDashboard.amountPerMethod?.colors || [];
    },
    amountPerMethodChartData() {
      return this.mapArrayWithPercentage(
        this.financialDashboard.amountPerMethod?.data
      );
    },
    amountPerStatusChartLabels() {
      return this.financialDashboard.amountPerStatus?.labels || [];
    },
    amountPerStatusChartColors() {
      return this.financialDashboard.amountPerStatus?.colors || [];
    },
    amountPerStatusChartData() {
      return this.mapArrayWithPercentage(
        this.financialDashboard.amountPerStatus?.data
      );
    },
    amountsPerCostCenterChartData() {
      return {
        labels: this.financialDashboard.amountPerCostCenter?.labels,
        datasets: [
          {
            backgroundColor: this.financialDashboard.amountPerCostCenter
              ?.colors,
            data: this.financialDashboard.amountPerCostCenter?.data
          }
        ]
      };
    },
    amountsPerTypeChartData() {
      return {
        labels: this.financialDashboard.amountPerType?.labels,
        datasets: [
          {
            backgroundColor: this.financialDashboard.amountPerType?.colors,
            data: this.financialDashboard.amountPerType?.data
          }
        ]
      };
    },
    amountsPerMethodChartData() {
      return {
        labels: this.financialDashboard.amountPerMethod?.labels,
        datasets: [
          {
            backgroundColor: this.financialDashboard.amountPerMethod?.colors,
            data: this.financialDashboard.amountPerMethod?.data
          }
        ]
      };
    },
    amountsPerStatusChartData() {
      return {
        labels: this.financialDashboard.amountPerStatus?.labels,
        datasets: [
          {
            backgroundColor: this.financialDashboard.amountPerStatus?.colors,
            data: this.financialDashboard.amountPerStatus?.data
          }
        ]
      };
    },
    bardata() {
      return {
        labels: this.financialDashboard.barChart?.labels,
        datasets: [
          {
            label: this.$t("data-title-1"),
            backgroundColor: "#92DBB0",
            data: this.financialDashboard.barChart?.revenue
          },
          {
            label: this.$t("data-title-2"),
            backgroundColor: "#FFA3AB",
            data: this.financialDashboard.barChart?.expense
          },
          {
            label: this.$t("data-title-3"),
            backgroundColor: "#ABBDFC",
            data: this.financialDashboard.barChart?.balance
          }
        ]
      };
    }
  },
  methods: {
    ...mapActions(["pipelinesRequest", "financialDashboardRequest"]),
    async doRequests() {
      this.loading = true;
      await this.financialDashboardRequest();
      this.$refs.barChart?.render();
      this.$refs.amountPerCostCenterChart?.render();
      this.$refs.amountPerTypeChart?.render();
      this.$refs.amountPerMethodChart?.render();
      this.$refs.amountPerStatusChart?.render();
      this.loading = false;
    },
    mapArrayWithPercentage(array) {
      let total = 0;
      let aux = [];

      if (array) {
        array.forEach(e => {
          total += Number(e);
        });

        aux = array.map(e => {
          const percentage = Math.round((e * 100) / total) + "%";

          return `${this.$options.filters.valueToReais(e)} (${percentage})`;
        });
      }

      return aux;
    }
  },
  async mounted() {
    if (this.pipelines.length <= 0) {
      await this.pipelinesRequest();
    }

    const today = new Date();
    const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);

    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit"
    };

    const payload = {
      pipeline_id: this.pipelines[0]?.id,
      from_date: firstDay.toLocaleString("fr-CA", options),
      to_date: today.toLocaleString("fr-CA", options)
    };

    if (!this.dashboardFilter.pipeline_id) {
      this.$store.commit("setDashboardFilter", payload);
    }

    if (payload.pipeline_id) {
      this.doRequests();
    } else {
      this.loading = false;
    }
  }
};
</script>

<style></style>
