<script>
import { Bar } from "vue-chartjs";
import ChartDataLabels from "chartjs-plugin-datalabels";

export default {
  extends: Bar,
  props: {
    data: {
      type: Object,
      default: null
    },
    suggestedMax: {
      type: Number,
      default: 50
    }
  },
  data() {
    return {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          labels: {
            fontSize: 14,
            fontFamily: "Poppins",
            fontColor: "#424c70",
            boxWidth: 14
          }
        },
        tooltips: {
          backgroundColor: "#424c70",
          titleFontFamily: "Poppins",
          bodyFontFamily: "Poppins",
          displayColors: false
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                color: "rgba(0, 0, 0, 0)",
                zeroLineColor: "rgba(0, 0, 0, 0)",
                drawBorder: false
              },
              ticks: {
                fontColor: "#424c70",
                fontFamily: "Poppins"
              }
            }
          ],
          yAxes: [
            {
              gridLines: {
                color: "#dfe2ec",
                zeroLineColor: "#dfe2ec"
              },
              ticks: {
                beginAtZero: true,
                stepSize: this.dynamicSteps(),
                fontColor: "#424c70",
                fontFamily: "Poppins",
                suggestedMax: this.suggestedMax
              }
            }
          ]
        },
        plugins: {
          datalabels: {
            color: "#424c70",
            font: {
              size: 14,
              family: "Poppins"
            },
            offset: 8,
            align: "end",
            anchor: "end",
            display: function(context) {
              if (
                context.dataset.label.includes("Contas") ||
                context.dataset.label.includes("Saldo")
              ) {
                return false;
              }

              return context.dataset.data[context.dataIndex] !== 0; // or >= 1 or ...
            }
          }
        }
      }
    };
  },
  methods: {
    render() {
      this.renderChart(this.data, this.options);
    },
    dynamicSteps() {
      let type = "ten";

      this.data.datasets.forEach(dataset => {
        if (!dataset?.data) return;
        dataset.data.forEach(value => {
          if (value < -100 || value > 100) {
            type = "hundred";
          }
          if (value < -1000 || value > 1000) {
            type = "thousand";
          }
          if (value < -10000 || value > 10000) {
            type = "ten_thousand";
          }
          if (value < -100000 || value > 100000) {
            type = "hundred_thousand";
          }
        });
      });

      if (type === "hundred") {
        return 100;
      }

      if (type === "thousand") {
        return 1000;
      }

      if (type === "ten_thousand") {
        return 10000;
      }

      if (type === "hundred_thousand") {
        return 100000;
      }

      return 10;
    }
  },
  mounted() {
    this.addPlugin([ChartDataLabels]);
  }
};
</script>

<style lang="scss">
#bar-chart {
  width: 100% !important;
}
</style>
