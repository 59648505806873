<i18n>
{
  "en": {
    "in-the-period-from": "In the period from",
    "to": "to",
    "data-title-1": "Tasks",
    "data-title-2": "Finished",
    "data-title-3": "Pending",
    "data-title-4": "Overdue",
    "data-title-5": "To finish",
    "in-the-period": "In the period",
    "average-time": "Average Time",
    "average-time-to-finish-by-responsible": "Average time",
    "total-by": "Total by",
    "type": "Type",
    "responsible": "Responsible",
    "creator": "Creator",
    "source": "Source",
    "total": "Total",
    "not-found": "Unable to generate the report",
    "not-found-description": "We noticed you haven't created a pipeline yet. To generate a report you'll need to create one. Shall we start?",
    "not-found-action": "Click here to create"
  },
  "pt-BR": {
    "in-the-period-from": "No período de",
    "to": "até",
    "data-title-1": "Tarefas",
    "data-title-2": "Finalizadas",
    "data-title-3": "Pendentes",
    "data-title-4": "Vencidas",
    "data-title-5": "Para finalizar",
    "in-the-period": "No período",
    "average-time": "Tempo médio",
    "average-time-to-finish-by-responsible": "Tempo médio",
    "total-by": "Total por",
    "type": "Tipo",
    "responsible": "Responsável",
    "creator": "Criador",
    "source": "Fonte",
    "total": "Total",
    "not-found": "Não foi possível gerar o relatório",
    "not-found-description": "Nós percebemos aqui que você não criou nenhum pipeline ainda. Para gerar o relatório você vai precisar criar um pipeline primeiro. Que tal criar um agora?",
    "not-found-action": "Clique aqui para criar"
  }
}
</i18n>

<template>
  <div class="board-content">
    <div class="dashboard-content" v-if="pipelines.length > 0">
      <div class="dashboard-title">
        <font-awesome-icon icon="calendar-days" />
        {{ $t("in-the-period-from") }}
        <b>{{ dashboardFilter.from_date | brDateTextWithoutYear }}</b>
        {{ $t("to") }}
        <b>{{ dashboardFilter.to_date | brDateTextWithoutYear }}</b>
      </div>
      <div class="general-info">
        <div class="card deal">
          <div class="card-icon">
            <font-awesome-icon icon="tasks" />
          </div>
          <div class="card-data">
            <div class="card-title">
              {{ $t("data-title-1") }}
            </div>
            <div class="card-subtitle">
              {{ $t("in-the-period") }}
            </div>
            <div class="card-info">
              {{ tasksDashboard.total }}
            </div>
          </div>
        </div>
        <div class="card win">
          <div class="card-icon">
            <font-awesome-icon icon="check" />
          </div>
          <div class="card-data">
            <div class="card-title">
              {{ $t("data-title-2") }}
            </div>
            <div class="card-subtitle">
              {{ $t("in-the-period") }}
            </div>
            <div class="card-info">
              {{ tasksDashboard.total_finished }}
            </div>
          </div>
        </div>
        <div class="card deal">
          <div class="card-icon">
            <font-awesome-icon icon="calendar-days" />
          </div>
          <div class="card-data">
            <div class="card-title">
              {{ $t("data-title-3") }}
            </div>
            <div class="card-subtitle">
              {{ $t("in-the-period") }}
            </div>
            <div class="card-info">
              {{ tasksDashboard.total_pending }}
            </div>
          </div>
        </div>
        <div class="card abandon">
          <div class="card-icon">
            <font-awesome-icon icon="ban" />
          </div>
          <div class="card-data">
            <div class="card-title">
              {{ $t("data-title-4") }}
            </div>
            <div class="card-subtitle">
              {{ $t("in-the-period") }}
            </div>
            <div class="card-info">
              {{ tasksDashboard.total_overdue }}
            </div>
          </div>
        </div>
        <div class="card time">
          <div class="card-icon">
            <font-awesome-icon icon="hourglass-half" />
          </div>
          <div class="card-data">
            <div class="card-title">
              {{ $t("data-title-5") }}
            </div>
            <div class="card-subtitle">
              {{ $t("average-time") }}
            </div>
            <div class="card-info">
              {{ tasksDashboard.avarage_time_to_finish }}
            </div>
          </div>
        </div>
      </div>
      <div class="deals-line bar-table ">
        <div class="user-charts">
          <bar-chart :data="bardata" ref="barChart"></bar-chart>
        </div>
        <we-table
          class="tasks"
          :labels="[
            $t('responsible'),
            $t('average-time-to-finish-by-responsible')
          ]"
          :columns="[tableLabels, tableAvarageTimeToFinish]"
        />
      </div>
      <div class="pizza-charts">
        <div class="chart-box">
          <div class="chart-title">{{ $t("total-by") }} {{ $t("type") }}</div>
          <div class="chart-grid">
            <doughnut-chart
              class="doughnut-chart"
              :data="totalsPerTypeChartData"
              ref="totalPerTypeChart"
            ></doughnut-chart>
            <we-table
              :labels="[$t('type'), $t('total')]"
              :columns="[totalPerTypeChartLabels, totalPerTypeChartData]"
              :colors="totalPerTypeChartColors"
            />
          </div>
        </div>
        <div class="chart-box">
          <div class="chart-title">
            {{ $t("total-by") }} {{ $t("responsible") }}
          </div>
          <div class="chart-grid">
            <doughnut-chart
              class="doughnut-chart"
              :data="totalsPerResponsibleChartData"
              ref="totalPerResponsibleChart"
            ></doughnut-chart>
            <we-table
              :labels="[$t('responsible'), $t('total')]"
              :columns="[
                totalPerResponsibleChartLabels,
                totalPerResponsibleChartData
              ]"
              :colors="totalPerResponsibleChartColors"
            />
          </div>
        </div>
        <div class="chart-box">
          <div class="chart-title">
            {{ $t("total-by") }} {{ $t("creator") }}
          </div>
          <div class="chart-grid">
            <doughnut-chart
              class="doughnut-chart"
              :data="totalsPerCreatorChartData"
              ref="totalPerCreatorChart"
            ></doughnut-chart>
            <we-table
              :labels="[$t('creator'), $t('total')]"
              :columns="[totalPerCreatorChartLabels, totalPerCreatorChartData]"
              :colors="totalPerCreatorChartColors"
            />
          </div>
        </div>
        <div class="chart-box">
          <div class="chart-title">{{ $t("total-by") }} {{ $t("source") }}</div>
          <div class="chart-grid">
            <doughnut-chart
              class="doughnut-chart"
              :data="totalsPerSourceChartData"
              ref="totalPerSourceChart"
            ></doughnut-chart>
            <we-table
              :labels="[$t('source'), $t('total')]"
              :columns="[totalPerSourceChartLabels, totalPerSourceChartData]"
              :colors="totalPerSourceChartColors"
            />
          </div>
        </div>
      </div>
    </div>
    <we-loading-overflow :loading="loading" />
    <we-not-found v-if="!loading && (!pipelines || pipelines.length === 0)">
      <template #title> {{ $t("not-found") }} </template>
      <template #description>
        {{ $t("not-found-description") }}
        <router-link
          :to="{ name: 'pipelinesConfigs' }"
          class="primary--text"
          active-class="active"
        >
          {{ $t("not-found-action") }}
        </router-link>
      </template>
      <template #picture>
        <img
          src="@/assets/undraw/dashboard.svg"
          alt="Onboarding"
          width="299"
          height="254"
        />
      </template>
    </we-not-found>
  </div>
</template>

<script>
import BarChart from "@/components/charts/Bar.vue";
import DoughnutChart from "@/components/charts/Doughnut.vue";
import { mapActions } from "vuex";
export default {
  components: {
    BarChart,
    DoughnutChart
  },
  data() {
    return {
      loading: true,
      dialogFilter: false
    };
  },
  computed: {
    dealsDashboard() {
      return this.$store.getters.getDealsDashboard;
    },
    tasksDashboard() {
      return this.$store.getters.getTasksDashboard;
    },
    dashboardFilter() {
      return this.$store.getters.getDashboardFilter;
    },
    pipelineSelected() {
      return (
        this.pipelines.find(e => e.id === this.dashboardFilter.pipeline_id) ||
        {}
      );
    },
    pipelines() {
      return this.$store.getters.getPipelines;
    },
    lineChartLabels() {
      return this.tasksDashboard?.lineChart?.labels || [];
    },
    lineChartData() {
      return this.tasksDashboard?.lineChart?.sata || [];
    },
    tableLabels() {
      return this.tasksDashboard?.table?.labels || [];
    },
    tableAvarageTimeToFinish() {
      return this.tasksDashboard?.table?.avarageTimeToFinish || [];
    },
    totalPerTypeChartLabels() {
      return this.tasksDashboard.totalPerType?.labels || [];
    },
    totalPerTypeChartColors() {
      return this.tasksDashboard.totalPerType?.colors || [];
    },
    totalPerTypeChartData() {
      return this.mapArrayWithPercentage(
        this.tasksDashboard.totalPerType?.data
      );
    },
    totalPerResponsibleChartLabels() {
      return this.tasksDashboard.totalPerResponsible?.labels || [];
    },
    totalPerResponsibleChartColors() {
      return this.tasksDashboard.totalPerResponsible?.colors || [];
    },
    totalPerResponsibleChartData() {
      return this.mapArrayWithPercentage(
        this.tasksDashboard.totalPerResponsible?.data
      );
    },
    totalPerCreatorChartLabels() {
      return this.tasksDashboard.totalPerCreator?.labels || [];
    },
    totalPerCreatorChartColors() {
      return this.tasksDashboard.totalPerCreator?.colors || [];
    },
    totalPerCreatorChartData() {
      return this.mapArrayWithPercentage(
        this.tasksDashboard.totalPerCreator?.data
      );
    },
    totalPerSourceChartLabels() {
      return this.tasksDashboard.totalPerSource?.labels || [];
    },
    totalPerSourceChartColors() {
      return this.tasksDashboard.totalPerSource?.colors || [];
    },
    totalPerSourceChartData() {
      return this.mapArrayWithPercentage(
        this.tasksDashboard.totalPerSource?.data
      );
    },
    bardata() {
      return {
        labels: this.tasksDashboard.barChart?.labels,
        datasets: [
          {
            label: this.$t("data-title-2"),
            backgroundColor: "#92DBB0",
            data: this.tasksDashboard.barChart?.finished
          },
          {
            label: this.$t("data-title-3"),
            backgroundColor: "#ABBDFC",
            data: this.tasksDashboard.barChart?.pending
          },
          {
            label: this.$t("data-title-4"),
            backgroundColor: "#FFA3AB",
            data: this.tasksDashboard.barChart?.overdue
          }
        ]
      };
    },
    totalsPerTypeChartData() {
      return {
        labels: this.tasksDashboard.totalPerType?.labels,
        datasets: [
          {
            backgroundColor: this.tasksDashboard.totalPerType?.colors,
            data: this.tasksDashboard.totalPerType?.data
          }
        ]
      };
    },
    totalsPerResponsibleChartData() {
      return {
        labels: this.tasksDashboard.totalPerResponsible?.labels,
        datasets: [
          {
            backgroundColor: this.tasksDashboard.totalPerResponsible?.colors,
            data: this.tasksDashboard.totalPerResponsible?.data
          }
        ]
      };
    },
    totalsPerCreatorChartData() {
      return {
        labels: this.tasksDashboard.totalPerCreator?.labels,
        datasets: [
          {
            backgroundColor: this.tasksDashboard.totalPerCreator?.colors,
            data: this.tasksDashboard.totalPerCreator?.data
          }
        ]
      };
    },
    totalsPerSourceChartData() {
      return {
        labels: this.tasksDashboard.totalPerSource?.labels,
        datasets: [
          {
            backgroundColor: this.tasksDashboard.totalPerSource?.colors,
            data: this.tasksDashboard.totalPerSource?.data
          }
        ]
      };
    }
  },
  methods: {
    ...mapActions(["pipelinesRequest", "tasksDashboardRequest"]),
    async doRequests() {
      this.loading = true;
      await this.tasksDashboardRequest();
      this.$refs.barChart?.render();
      this.$refs.totalPerTypeChart?.render();
      this.$refs.totalPerResponsibleChart?.render();
      this.$refs.totalPerCreatorChart?.render();
      this.$refs.totalPerSourceChart?.render();
      this.loading = false;
    },
    mapArrayWithPercentage(array) {
      let total = 0;
      let aux = [];

      if (array) {
        array.forEach(e => {
          total += Number(e);
        });

        aux = array.map(e => {
          const percentage = Math.round((e * 100) / total) + "%";

          return `${e} (${percentage})`;
        });
      }

      return aux;
    }
  },
  async mounted() {
    if (this.pipelines.length <= 0) {
      await this.pipelinesRequest();
    }

    const today = new Date();
    const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);

    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit"
    };

    const payload = {
      pipeline_id: this.pipelines[0]?.id,
      from_date: firstDay.toLocaleString("fr-CA", options),
      to_date: today.toLocaleString("fr-CA", options)
    };

    if (!this.dashboardFilter.pipeline_id) {
      this.$store.commit("setDashboardFilter", payload);
    }

    if (payload.pipeline_id) {
      this.doRequests();
    } else {
      this.loading = false;
    }
  }
};
</script>

<style></style>
